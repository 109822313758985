<template>
	<div class="header_mto">
		<img class="headerimg" src="@/assets/images/img_5(4).jpg" />
		<img class="headerimg" src="@/assets/images/img_5(3).jpg" />
		<div class="headerfont cannedfont">
			<p>Canned</p>
			<p>Goods</p>
		</div>
	</div>
	<div id="bg_page">
		<div class="bg_content">
			<div class="baked_column">
				<div class="canned_goods">
					<div class="hdr">Jams & Jellies</div>
					<div class="bg_item">
						<span>Apple Butter</span>
						<span>Apple Jam</span>
						<span>Apricot</span>
						<span>Blackberry</span>
						<span>Black Raspberry</span>
						<span>Blueberry</span>
						<span>Cranberry</span>
						<span>Peach</span>
						<span>F.R.O.G. Jam</span>
						<span>Garlic Jam</span>
						<span>Sweet Pepper Jam</span>
						<span>Red Hot Pepper Jam</span>
						<span>Horse Radish</span>
						<span>Tomato</span>
						<span>Triple Treat</span>
						<span>Peach Marmalade</span>
						<span>Apricot-Pineapple</span>
						<span>Rhubarb</span>
						<span>Pineapple</span>
						<span>Grape</span>
						<span>Plum</span>
						<span>Quince</span>
						<span>Red Raspberry</span>
						<span>Strawberry Rhubarb</span>
						<span>Strawberry</span>
						<span>Traffic Jam</span>
					</div>
				</div>
				<div class="canned_goods">
					<div class="hdr">&mdash;No Sugar&mdash;</div>
					<div class="bg_item">
						<span>Apple Butter</span>
						<span>Apricot</span>
						<span>Blueberry</span>
						<span>Black Raspberry</span>
						<span>Strawberry</span>
						<span>Red Raspberry</span>
					</div>
				</div>
				<div class="canned_goods">
					<div class="hdr">Eggs & Relish</div>
					<div class="bg_item">
						<span>Bread & Butter</span>
						<span>Chow-Chow</span>
						<span>Red Beets</span>
						<span>7-Day Sweets</span>
						<span>Sauerkraut</span>
						<span>Beet Eggs</span>
						<span>Buffalo Eggs</span>
						<span>Mustard Eggs</span>
						<span>Jalapeno Eggs</span>
						<span>Pickled Mushrooms</span>
						<span>Dilly Bean</span>
						<span>Corn Relish</span>
						<span>Hot Pepper Relish</span>
						<span>Tomato Relish</span>
						<span>Onion Relish</span>
						<span>Zucchini Relish</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
